import React from 'react'

const Itens = React.lazy(() => import('./views/itens'))
const DetalhesItem = React.lazy(() => import('./views/itens/detalhes'))

const Caixas = React.lazy(() => import('./views/caixas'))
const DetalhesCaixa = React.lazy(() => import('./views/caixas/detalhes'))

const Clientes = React.lazy(() => import('./views/clientes'))
const DetalhesCliente = React.lazy(() => import('./views/clientes/detalhes'))

const Pedidos = React.lazy(() => import('./views/pedidos'))
const DetalhesPedido = React.lazy(() => import('./views/pedidos/detalhes'))

const Doacoes = React.lazy(() => import('./views/doacoes'))
const DetalhesDoacao = React.lazy(() => import('./views/doacoes/detalhes'))

const Instituicoes = React.lazy(() => import('./views/instituicoes'))
const DetalhesInstituicao = React.lazy(() => import('./views/instituicoes/detalhes'))

const Pix = React.lazy(() => import('./views/pix'))

const AlterarSenha = React.lazy(() => import('./views/alterar-senha'))


// const Influenciadores = React.lazy(() => import('./views/influenciadores'))
// const DetalhesInfluenciador = React.lazy(() => import('./views/influenciadores/detalhes'))

// const Assinaturas = React.lazy(() => import('./views/assinaturas'))
// const DetalhesAssinatura = React.lazy(() => import('./views/assinaturas/detalhes'))

// const Campanhas = React.lazy(() => import('./views/campanhas'))
// const DetalhesCampanha = React.lazy(() => import('./views/campanhas/detalhes'))

// const Sorteios = React.lazy(() => import('./views/campanhas/sorteios'))
// const DetalhesSorteio = React.lazy(() => import('./views/campanhas/sorteios/detalhes'))

// const Premios = React.lazy(() => import('./views/campanhas/sorteios/premios'))
// const DetalhesPremio = React.lazy(() => import('./views/campanhas/sorteios/premios/detalhes'))

// const Planos = React.lazy(() => import('./views/planos'))
// const DetalhesPlano = React.lazy(() => import('./views/planos/detalhes'))

// const ResultadosSorteios = React.lazy(() => import('./views/resultados-sorteios'))
// const DetalhesResultadoSorteio = React.lazy(() => import('./views/resultados-sorteios/detalhes'))
// const RegistrarResultadoSorteio = React.lazy(() => import('./views/resultados-sorteios/registrar'))

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))


const routes = [
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },

  { path: '/itens', name: 'Itens', element: Itens },
  { path: '/itens/:id', name: 'Detalhes do Item', element: DetalhesItem },

  { path: '/caixas', name: 'Caixas', element: Caixas },
  { path: '/caixas/:id', name: 'Detalhes da Caixa', element: DetalhesCaixa },

  { path: '/clientes', name: 'Clientes', element: Clientes },
  { path: '/clientes/:id', name: 'Detalhes do Cliente', element: DetalhesCliente },
  
  { path: '/pedidos', name: 'Pedidos', element: Pedidos },
  { path: '/pedidos/:id', name: 'Detalhes do Pedido', element: DetalhesPedido },

  { path: '/doacoes', name: 'Doações', element: Doacoes },
  { path: '/doacoes/:id', name: 'Detalhes da Doação', element: DetalhesDoacao },

  { path: '/instituicoes', name: 'Instituições', element: Instituicoes },
  { path: '/instituicoes/:id', name: 'Detalhes da Instituição', element: DetalhesInstituicao },

  { path: '/pix', name: 'Pix', element: Pix },

  { path: '/alterar-senha', name: 'Alterar Senha', element: AlterarSenha },

]

export default routes
